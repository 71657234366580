import React from 'react';
import LeftBadgeImage from '../LeftBadgeImage/leftBadgeImage'
import PropTypes from 'prop-types';
import ButtonLink from "../acbuttonlink";
import './biLateralBlock.scss'
import SafeHTML from "../safehtml";
import CTAList from "../CTAList/ctaList"
import { BiLateralStyleListTypes } from "../../assets/js/utils"


const renderEyebrow = (eyebrow, style) => {
    return (
        eyebrow && <h5 className="eyebrow"> {eyebrow} </h5>
    )
}

const renderTitle = (bilateralTitle) => {
    return (
        bilateralTitle && bilateralTitle.title && <h3 className="bilateral-title"> {bilateralTitle.title} </h3>
    )
}

export default function BiLateralBlock(props) {

    const { eyebrow, bilateralTitle, bilateralDescription, resourceHeader, resourceList, style, displayImageTop, showBadge } = props;

    return (
        <section className={`bilateral-section ${showBadge ? "has-badge-icon": ""} ${BiLateralStyleListTypes[style] === BiLateralStyleListTypes["BiLateral Style 1"] ? "style-1" : "style-2"} ${displayImageTop ? "image-on-top" : "image-on-bottom"}`}>
            <div className="container">
                <div className={`row no-gutters ${displayImageTop ? "column-reverse-mob" : ""}`}>
                    <div className="col-md text-copy">
                        <div className="bilateral-content has-top-line">

                            {BiLateralStyleListTypes[style] === BiLateralStyleListTypes["BiLateral Style 1"] &&
                                <div>
                                    {renderEyebrow(eyebrow, style)}
                                    {renderTitle(bilateralTitle)}
                                </div>
                            }

                            {BiLateralStyleListTypes[style] === BiLateralStyleListTypes["BiLateral Style 2"] &&
                                <div>
                                    {renderTitle(bilateralTitle)}
                                    {renderEyebrow(eyebrow, style)}
                                </div>
                            }

                            {bilateralDescription && <div className="bilateral-desc"><SafeHTML>
                                {bilateralDescription}
                            </SafeHTML>
                            </div>
                            }
                            <CTAList resourceHeader={resourceHeader} resourceList={resourceList} />

                            {props.cta &&
                                <ButtonLink {...props.cta} />
                            }
                        </div>
                    </div>
                    <div className="col-md">
                        <LeftBadgeImage {...props} />
                    </div>
                </div>
            </div>

        </section>
    )

}

BiLateralBlock.propTypes = {
    eyebrow: PropTypes.string,
    bilateralTitle: PropTypes.object,
    bilateralDescription: PropTypes.object,
    resourceHeader: PropTypes.string,
    resourceList: PropTypes.array,
    cta: PropTypes.object,
}